const propertyData = {
    "url": "http://5879NWLandingDr.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "5879 NW Landing Dr", "city": "Portland", "state":"Oregon", "zipcode": "97229"},
    "display_address": "5879 NW Landing Dr",
    "property": {
      "listing_price": "$439,888",
      "bedrooms": "3",
      "baths": "2.5",
      "sq_feet": "1653",
      "lot_size": "5227",
      "rmls": "20235430",
      "built_in": "1991",
      "blurb": "Light & bright Rock Creek home with vaulted ceilings and skylights. Newer roof, exterior and interior paint and water heater, disposal. Recent exterior and fireplace maintenance.  Kitchen boasts maple cabinets, silestone counters, stainless steel appliances and pantry. Master suite w/ walk-in closet with built-in custom storage & Hunter Douglas black out blinds.  Beautiful backyard with deck and raised garden beds.  Solar panels for energy efficiency!",
      "ammenities": [
        
      ]
    },
    "image_count": 26,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.3985794270493!2d-122.89614808443922!3d45.562422779102306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549505ea1cacf9cb%3A0xf5b995e1dde84bec!2s5879%20NW%20Landing%20Dr%2C%20Portland%2C%20OR%2097229!5e0!3m2!1sen!2sus!4v1584503704742!5m2!1sen!2sus",
        "youtube":"KNd0_Y4FxPQ"
    }
  }

export default propertyData;
